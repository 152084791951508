.course_details_sidebar {
    border-right: 1px solid rgba(102, 102, 102, 0.93);
    width: 480px;
    position: fixed;
    height: 100vh;
    padding: 60px 40px 0px;
    z-index: 9;
}

.course_component {
    bottom: 0;
    background: #fafafa;
    border-top: solid 1px #eee;
    margin-bottom: 45px;
    border: 1px solid #CDCDCD;
    position: relative;

}

.course_content_menu {
    padding: 25px 35px;
    text-align: center;
    border-right: 1px solid #CDCDCD;
}

.content_icon {
    width: 25px;
    height: 25px;
}

.course-modules .add_module {
    border: 1px dashed #9E9E9E;
    text-align: left;
    padding: 10px 23px;
    font-weight: 600;
    margin-top: 1rem;
}

.module_title img,
svg {
    padding-right: 10px;
}

.module_title {
    justify-content: space-between;
    display: flex;
    padding: 16px;
    border-radius: 5px 5px 0px 0px;
    border-top: 1px solid #9E9E9E;
    border-right: 1px solid #9E9E9E;
    border-left: 1px solid #9E9E9E;
    /* opacity: 0.8; */
    font-size: 16px;
    color: #000;
}

.image_upload {
    border: 1px solid #9E9E9E;
    text-align: center;
    padding: 80px;
}

.img-upload {
    position: absolute;
    top: 0px;
    right: auto;
    left: 51%;
    width: 36px;
    height: 26px;
    border: none;
    background-color: #fff;
    border-left: 1px solid #ccc;
    z-index: 99;
    margin-top: 5px;
    margin-left: 1rem;
}

.img-upload:hover{
    background-color: #f0f0f0;
}

.video_upload {
    border-radius: 2px;
    border: 1px solid #9E9E9E;
    background: #F5F5F5;
    text-align: center;
    margin: 0 auto;
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-evenly;
    align-items: center;
    min-height: 300px;
    height: auto;
}

.video_popup input[type=number], .video_popup .form-select {
    border-radius: 2px;
    border: 1px solid #9E9E9E;
    background: #F5F5F5;
}

.btn-black {
    --bs-btn-color: #fff !important;
    --bs-btn-bg: #595959 !important;
    --bs-btn-border-color: #595959;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #595959;
    --bs-btn-hover-border-color: #595959;
    --bs-btn-focus-shadow-rgb: 60, 153, 110;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #595959;
    --bs-btn-active-border-color: #595959;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #595959;
    --bs-btn-disabled-border-color: #595959;
}

.video_popup {
    border: 1px solid #9E9E9E;
    padding: 20px 35px 20px;
}

.check-option {
    border-radius: 2px;
    border: 1px solid #9E9E9E;
    padding: 15px;
}

.form-check .form-check-input {
    margin-left: -0.5em !important;
}

.add_option {
    color: #22A298 !important;
    text-decoration: none;
}

.module-box {
    border-radius: 5px;
    background: #FFF;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.16);
    padding: 10px;
    width: 100%;
    margin-bottom: 20px;
}

.module-box img {
    margin-right: 12px;
}

.dropdown-toggle::after {
    display: inline-block;
    margin-left: 0px !important;
    vertical-align: 0px !important;
    content: "";
    border-top: 0px solid;
    border-right: 0px solid transparent !important;
    border-bottom: 0;
    border-left: 0px solid transparent !important;
}