@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@400&display=swap');

body {
  margin: 0;
  font-family: 'Outfit';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --bs-body-font-family: var(--bs-font-inter);
}

:root {
  --bs-body-second: #F5F5F5 !important;
  --bs-font-inter: 'Outfit';
}

::-ms-input-placeholder { /* Edge 12-18 */
  color: #999 !important;
}

::placeholder {
  color: #999 !important;
}

::-webkit-scrollbar {
  width: 6px;
  height:6px;
}

::-webkit-scrollbar-thumb {
  background: transparent;
  border-radius: 6px; 
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb:hover {
  background: #DCE2E5; 
}

.btn-primary{
  border-radius: 4px !important;
  background: #146B90 !important;
  border-color: #146B90 !important;
  padding: 12px 12px !important;
  font-weight: 500;
}

.mr-10 {
  margin-right: 20px;
}

.mt-100 {
  margin-top: 100px;
}

.w-35 {
  width: 35%;
}

.mt-70 {
  margin-top: 70px;
}

.mt-60 {
  margin-top: 60px;
}

.mb-35 {
  margin-bottom: 35px;
}

.pl-20 {
  padding-left: 20px;
}

b.fw-regular {
  font-weight: 600;
}

.page__main{
  padding: 28px 60px 0px;
}

.card-box {
  border-radius: 4px;
  background: #FFF;
}

.course_count {
  padding: 12px 30px;
  font-size: 18px;
  font-weight: 600;
}

.right-border {
  border-right: 1px solid #CDCDCD;
  height: auto;
}

.courses_create {
  padding: 12px 20px;
  font-weight: 500;
}

.card {
  border-radius: 5px 5px 0px 0px !important;
  border: 1px solid #E2E2E2 !important;
}

.border-table {
  border: 1px solid #E2E2E2;
}

.thead-dark {
  --bs-table-bg: #E2E2E2;
}

.has-search .form-control {
  padding-left: 3rem !important;
}

.has-search .form-control-feedback {
  position: absolute;
  z-index: 2;
  display: block;
  width: 2rem;
  height: 2rem;
  line-height: 2rem;
  text-align: center;
  pointer-events: none;
  color: #aaa;
  padding: 7px 5px 0px 15px;
}

.has-search .form-control {
  border: 1px solid #999;
  background: #FFF;
  border-radius: 61px;
}

.form-control:focus {
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  border-color: #999999 !important;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 0%) !important;
}

.red {
  color:red;
}

.btn-green {
  background: #25A2224D;
  order: 1;
}

.btn-pink {
  background: #ED483D4D;
  order: 2;
}

.btn-yellow {
  background: #FFC9004D;
  order: 3;
}

.status-btn {
  padding: 5px 16px;
  color: #000;
  text-decoration: none;
  border-radius: 25px;
  margin-left: 7px;
}

.circle-status {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 5px;
}

.green {
  background: #25A222;
}

.pink {
  background: #ED483D;
}

.yellow {
  background: #FFC900;
}

.primary_color {
  color: #146B90 !important;
}

.black_color {
  color: #000 !important;
}

.md-btn {
  padding-left: 70px !important;
  padding-right: 70px !important;
}

.font-18 {
  font-size: 18px;
}

.mlr-40 {
  margin: 0px 40px;
} 

.primary_color {
  color: #146B90 !important;
}

.ReactModal__Overlay{
  background-color: rgba(0, 0, 0, 0.45) !important;
  backdrop-filter: blur(2px) !important;
  z-index: 9999;
}

.ReactModal__Content {
 position: fixed;
 top: 35% !important;
 left: 50% !important;
 right: auto !important;
 bottom: auto !important;
 margin-right: -50% !important;
 border-radius: 20px !important;
 transform: translate(-50%, -50%) !important;
 width: 500px !important;
 outline: none !important;
}

.login-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.login-overlay .login-card{
  cursor: default;
  pointer-events: none;
  border-radius: 10px;
  padding: 40px;
  box-shadow: 0 5px 15px rgb(0 0 0 / 11%);
}

.login-overlay input {
  cursor: default;
  pointer-events: auto;
  padding: 0.725rem 1.125rem;
  font-size: 16px;
  box-shadow: none;
}

.form-control:focus {
  box-shadow: none !important;
  border: 1px solid #000 !important;
}

.login-overlay button{
  cursor: default;
  pointer-events: auto;
}

.close-button {
  position: absolute;
  right: 10px;
  top: 10px;
  background-color: transparent;
  outline: none;
  border: none;
  font-size: 1.5rem;
}

.forgot-password-btn {
  background-color: transparent;
  outline: none;
  border: none;
  color: #f14c38;
  font-size: 16px;
  padding: 0;
}

.otp-timer{
  color: #f14c38;
}

.g-recaptcha > div > div > div {
  margin-top: 15px;
  transform: scale(0.9);
  margin-left: -15px;
  pointer-events: auto;
  cursor: default;
}

.btn-link[disabled] {
  color: #c4baba !important;
}

.otp-input > div {
  display: flex;
  justify-content: center;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #191919;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  left: auto;
  right: 10px;
  border-radius: 8px;
  text-align: left;
  padding-top: 10px;
  padding-bottom: 10px;
}

.dropdown-content li a, .dropdown-content li a:hover {
  color: #fff;
  padding: 4px 16px;
  text-decoration: none;
  display: block;
}

.dropdown:hover .dropdown-content {display: block;}

.sidebar-menu a.active {
  border-radius: 4px !important;
  background: #146B90 !important;
  border-color: #146B90 !important;
  padding: 12px 16px !important;
  color: #fff !important;
  transform: translateX(-18px);
}

.sidebar-menu a:hover{
  color: #146B90;
}

.course-modules {
  height: 100%;
  max-height: 750px;
  overflow-y: scroll;
}

.course-modules .row{
  margin: 0 0 35px !important;
}

.course_component > div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.course-modules > div > div {
  padding: 10px;
  border-radius: 5px;
  background: #FFF;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.16);
  border: 0 !important;
  margin-top: 0.2rem;
}

.module-box h5{
  margin-bottom: 0 !important;
  font-size: 1rem;
}

.css-tj5bde-Svg {
  width: 30px !important;
}

.css-1fdsijx-ValueContainer, .css-3w2yfm-ValueContainer{
  padding: 0.375rem 0.75rem !important;
}

.css-12a83d4-MultiValueRemove {
  padding-left: 4px;
  padding-right: 0;
  margin-left: 0.5rem;
}

.form-control,
.form-select {
  padding: 0.675rem 0.75rem !important;
}

.form-select:focus {
  border-color: #dee2e6 !important;
  outline: 0;
  box-shadow: none !important;
}

.add-block {
  position: relative;
  width: 100%;
  background-color: transparent;
  outline: none;
  border: none;
}

.add-block span {
  border-radius: 24px;
  border: 1px solid #000;
  background: #D9D9D9;
  margin: 0 auto;
  padding: 6px 20px;
}

.add-block::before,
.add-block::after {
  content: '';
  position: absolute;
  height: 1px;
  width: 100%;
  border: 1px dashed #000;
  top: 10px;
  z-index: -9;
}

.add-block::before {
  left: 0;
  border-right: none;
}

.add-block::after {
  right: 0;
  border-left: none;
}

.ReactModal__Overlay{
  background-color: rgba(0, 0, 0, 0.45) !important;
  backdrop-filter: blur(2px) !important;
  z-index: 9999;
}

.ReactModal__Content {
 top: 50% !important;
 left: 50% !important;
 right: auto !important;
 bottom: auto !important;
 margin-right: -50% !important;
 border-radius: 20px !important;
 padding: 30px 40px 30px !important;
 transform: translate(-50%, -50%) !important;
 min-width: 600px !important;
 width: auto;
}

.ckeditor {
  position: relative;
}

.delete-block {
  position: absolute;
  top: 1px;
  right: 1px;
  padding: 7px 10px;
  outline: none;
  border: none;
}

button.btn.btn-outline-secondary,
button.add_markers_button, button.add_module {
    color: #22A298 !important;
    font-weight: 600;
    border: none;
    padding: 0;
    background-color: transparent !important;
}

.Toastify__toast-container {     z-index: 99999 !important; }

@media screen and (min-width: 1440px) {
  .course-modules {
    max-height: 750px !important;
  }
  .course_details {
    max-height: 780px !important;
    overflow-y: auto;
  }
}

@media screen and (min-width: 1280px) {
  .course-modules {
    max-height: 350px;
  }
  .course_details {
    max-height: 380px;
    overflow-y: auto;
  }
}