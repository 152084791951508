.course_details_sidebar {
    border-right: 1px solid rgba(102, 102, 102, 0.93);
    width: 350px;
    position: fixed;
    height: 100vh;
    padding: 60px 40px 0px;
}

.course_main {
    padding: 60px 35px 20px 35px;
    margin-left: 350px;
}
