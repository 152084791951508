.course_wrapper {
    position: relative;
    height: 100vh;
}

.navbar {
    border: 1px solid rgba(51, 22, 22, 0.93);
    background: #F5F5F5 !important;
    height: 100px;
    top: 0;
    position: fixed !important;
    left: 0;
    right: 0;
    bottom: auto;
    z-index: 9999;
}

.p-40 {
    padding: 39px;
}

.text-decoration-none {
    text-decoration: none;
}

ul {
    list-style-type: none;
}

/* li {
    float: left;
} */

li a {
    text-decoration: none;
    color: #9B9B9B;
}

li .active{
    color: #146B90;
}

.menu_vertical {
    border-left: 1px solid #000000;
    margin: 0px 24px;
    height: 1.25rem;
}

.main {
    padding-top: 100px;
} 

.cursor_pointer {
    cursor: pointer;
}