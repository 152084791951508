.wrapper {
    display: flex;
    position: relative;
    background-color: var(--bs-body-second) !important;
    height: 100vh;
}

.wrapper .sidebar {
    width: 350px;
    height: 100%;
    padding: 20px 45px 0px;
    position: fixed;
    top: 0;
    border-right: 2px solid #E2E2E2;
    background: #FDFDFD;
    z-index: 9999;
}

.wrapper .sidebar .navbar_logo {
    width: 220px;
    height: 82px;
    margin-bottom: 20px;
}

.wrapper .main_content {
    width: 100%;
    margin-left: 350px;
}

.wrapper .main_content .header {
    background: #fff;
    border: 1px solid #F7F6FB;
    height: 100px;
}